import React, { Component } from 'react'
import Link from 'next/link'
import dynamic from 'next/dynamic'

const OutlineLogo = dynamic(() => import('../../icons').then(i => i.OutlineLogo))

export default class Header extends Component {
  render() {
    return (
      <div className="Home-Header">
        <div className="logo">
          <Link href="/homepage" as="/homepage" passHref>
            <div>
              <OutlineLogo style={{ width: '200px', color: 'black' }} />
            </div>
          </Link>
        </div>
        <div className="menu">
          <a
            className="hide-when-xsmall"
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.kubesail.com/"
          >
            Docs
          </a>
          <Link prefetch={false} href="/pricing">
            Pricing
          </Link>
          <Link className="hide-on-small" prefetch={false} href="/login">
            Log In
          </Link>
          <Link className="call-to-action" prefetch={false} href="/login/register">
            Sign Up
          </Link>
        </div>
      </div>
    )
  }
}
